import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import COLORS from 'constants/colors';

import Tile, { TileDisclosure, TileHeader } from 'shared/Tile';

import Icon from 'components/elements/Icon';
import SpaceBetween from 'components/elements/SpaceBetween';

import UnloadItemModel from 'transports/model/UnloadItem';
import UnloadLeg from 'transports/model/UnloadLeg';
import UnloadableItem from 'transports/model/UnloadableItem';
import UnloadItem from './UnloadItem';
import UnloadableLostCoffeeModel from 'transports/model/UnloadableLostCoffee';
import UnloadableLostCoffee from './UnloadableLostCoffee';
import UnloadableAvailableExcessOrOrphanCoffee from './UnloadableAvailableExcessOrOrphanCoffee';
import UnloadableAvailableExcessOrOrphanCoffeeModel from 'transports/model/UnloadableAvailableExcessOrOrphanCoffee';
import UnloadableIco from './UnloadableIco';
import UnloadableIcoModel from '../model/UnloadableIco';

export interface UnloadTransportProps {
  leg: UnloadLeg;
}

const UnloadLegCard: React.FC<UnloadTransportProps> = ({ leg }) => {
  const [collapsed, setCollapsed] = useState(true);

  const renderTransportables = () => (
    <>
      {leg.unloadItems.map(
        (transportable: UnloadableItem | UnloadableIcoModel, index: number) => {
          if (transportable instanceof UnloadItemModel) {
            return <UnloadItem key={index} transportable={transportable} />;
          } else if (transportable instanceof UnloadableLostCoffeeModel) {
            return <UnloadableLostCoffee key={index} transportable={transportable} />;
          } else if (
            transportable instanceof UnloadableAvailableExcessOrOrphanCoffeeModel
          ) {
            return (
              <UnloadableAvailableExcessOrOrphanCoffee
                key={index}
                transportable={transportable}
              />
            );
          } else if (transportable instanceof UnloadableIcoModel) {
            return <UnloadableIco key={index} transportable={transportable} />;
          } else {
            return null;
          }
        }
      )}
    </>
  );

  return (
    <Tile key={leg.leg.local_identifier}>
      <TileHeader onClick={() => setCollapsed(!collapsed)}>
        <TileDisclosure
          collapsed={collapsed}
          handleCollapsible={() => setCollapsed(!collapsed)}
        />
        <SpaceBetween alignCenter={true}>
          <h2>{leg.leg.local_identifier}</h2>
          {leg.unloaded && <Icon icon='check' color={COLORS.GREEN} />}
        </SpaceBetween>
      </TileHeader>

      {!collapsed && (
        <table
          className={classNames('table', 'sets-table', {
            'sets-table--collapsed': true,
          })}
        >
          <thead>
            <tr>
              <th className='table-border align-left'>ID</th>

              <th className='table-border'>
                {I18n.translate('transports.receive.quality')}
              </th>

              <th className='table-border'>
                {I18n.translate('transports.receive.bags')}
              </th>

              <th className='table-border align-center'>
                {I18n.translate('transports.receive.weight')}
              </th>

              <th className='table-border'>{I18n.translate('attributes.tags')}</th>

              <th className='table-border transports--reason'></th>

              <th className='transports--unload-action'></th>
            </tr>
          </thead>
          <tbody>{renderTransportables()}</tbody>
        </table>
      )}
    </Tile>
  );
};

export default observer(UnloadLegCard);
