import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import useStores from 'utils/useStores';
import Icon from 'components/elements/Icon';

import type UnloadableAvailableExcessOrOrphanCoffeeTypeModel from 'transports/model/UnloadableAvailableExcessOrOrphanCoffee';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';

import UnloadTransportableOverlay from './UnloadTransportableOverlay';
import IdentifierTableData from 'shared/Table/components/IdentifierTableData';

export interface UnloadableAvailableExcessOrOrphanCoffeeProps {
  transportable: UnloadableAvailableExcessOrOrphanCoffeeTypeModel;
}

const ColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.375em;
  align-items: center;
`;

function UnloadableAvailableExcessOrOrphanCoffee({
  transportable,
}: UnloadableAvailableExcessOrOrphanCoffeeProps) {
  const { unloadStore } = useStores();

  return (
    <tr key={transportable.identifier}>
      <IdentifierTableData className='table-border align-left'>
        {transportable.identifier}
      </IdentifierTableData>

      <td className='table-border align-center'>
        <TextWithSub text={transportable.quality} sub={transportable.grade} />
      </td>

      <td className='table-border align-center'>
        <ColumnWrapper>
          <TextWithSub
            text={transportable.expectedUnits}
            sub={transportable.unloadedUnits || '--'}
          />
          <Icon icon={transportable.bagIcon.name} color={transportable.bagIcon.color} />
        </ColumnWrapper>
      </td>

      <td className='table-border align-center'>
        <ColumnWrapper>
          <TextWithSub
            text={`${transportable.expectedWeight} kg`}
            sub={`${transportable.unloadedWeight || '--'} kg`}
          />
          <Icon
            icon={transportable.weightIcon.name}
            color={transportable.weightIcon.color}
          />
        </ColumnWrapper>
      </td>

      <td className='table-border'>-</td>

      <td className='table-border transports--reason'>
        {transportable.reasonId ? unloadStore.reasonValue(transportable.reasonId) : ''}
      </td>

      <td>
        <UnloadTransportableOverlay transportable={transportable} />
      </td>
    </tr>
  );
}

export default observer(UnloadableAvailableExcessOrOrphanCoffee);
