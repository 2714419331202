import { computed, observable } from 'mobx';

import type { FullTransportLeg } from 'types/model/transportLeg';

import UnloadItem from './UnloadItem';
import UnloadableLostCoffee from './UnloadableLostCoffee';
import UnloadableItem from './UnloadableItem';
import IExportsTransportLeg from 'exports/types/ITransportLeg';
import UnloadableIco from './UnloadableIco';
import IDecafTransportLeg from 'decaf/types/ITransportLeg';
import Ico from 'decaf/types/Ico';
import Storage from './Storage';
import UnloadableAvailableExcessOrOrphanCoffee from './UnloadableAvailableExcessOrOrphanCoffee';

type UnloadLegOptions = {
  hasStorage?: boolean;
};

class UnloadLeg {
  public id: number;
  public leg: FullTransportLeg | IExportsTransportLeg | IDecafTransportLeg;
  public unloadItems = observable.array<UnloadableItem>();

  private options: UnloadLegOptions;
  private defaultOptions: UnloadLegOptions = {
    hasStorage: false,
  };

  constructor(
    leg: FullTransportLeg | IExportsTransportLeg | IDecafTransportLeg,
    options?: UnloadLegOptions
  ) {
    // TODO: Refactor with factories
    this.leg = leg;
    this.id = this.leg.id;
    this.options = options || this.defaultOptions;

    const unloadItems: any[] = [];

    if (leg.type === 'exports_transport_leg') {
      const { icos } = leg;

      unloadItems.push(...this.createItem(icos, UnloadableIco));
    } else if (leg.type === 'decaf_transport_leg') {
      const { icos } = leg;

      unloadItems.push(...icos.map(this.mapDecafIco));
    } else {
      const { lots, lot_sets, lost_coffees, milling_outputs } = leg;

      unloadItems.push(...this.createItem(lots, UnloadItem));
      unloadItems.push(...this.createItem(lot_sets, UnloadItem));
      unloadItems.push(...this.createItem(lost_coffees, UnloadableLostCoffee));
      unloadItems.push(
        ...this.createItem(milling_outputs, UnloadableAvailableExcessOrOrphanCoffee)
      );
    }

    this.unloadItems = observable.array(unloadItems);
  }

  private mapDecafIco = ({ main_identifier, weight, id, units, quality, grade }: Ico) => {
    let storage;

    if (this.options.hasStorage) {
      storage = new Storage();
    }

    return new UnloadableIco(
      {
        id,
        units,
        quality,
        grade,
        weight,
        identifier: main_identifier,
      },
      storage
    );
  };

  private createItem = <T, F>(
    items: T[],
    Class: new (item: T, storage: Storage) => F
  ): F[] =>
    items.map((item) => {
      const storage = new Storage();

      return new Class(item, storage);
    });

  @computed get unloaded(): boolean {
    const unloadedLegs = this.unloadItems.filter((item) => item.isUnloaded);
    return unloadedLegs.length === this.unloadItems.length;
  }
}

export default UnloadLeg;
