import { observable, action, computed } from 'mobx';

import AvailableExcessOrOrphanCoffee from 'types/model/availableExcessOrOrphanCoffee';
import type Storable from '../types/Storable';
import Storage from './Storage';
import COLORS from 'constants/colors';

class UnloadableAvailableExcessOrOrphanCoffee implements Storable {
  public available_item: AvailableExcessOrOrphanCoffee;

  public id: number;
  public identifier: string;
  public expectedUnits: number;
  public expectedWeight: number;
  public quality: string;
  public grade: number;
  public bags: number;
  public weight: number;
  public storage: Storage;

  @observable private unloaded = false;
  @observable public unloadedWeight: number | undefined;
  @observable public unloadedUnits: number | undefined;
  @observable public reasonId: number | undefined;

  public constructor(available_item: AvailableExcessOrOrphanCoffee, storage: Storage) {
    this.identifier = available_item.identifier;
    this.id = available_item.id;
    this.expectedUnits = 1;
    this.unloadedUnits = 0;
    this.expectedWeight = parseFloat(available_item.available_weight);
    this.unloadedWeight = 0;

    this.quality = available_item.quality;
    this.grade = available_item.grade;
    this.storage = storage;
  }

  @action setUnloaded = (status: boolean): void => {
    this.unloaded = status;
  };

  @action public setUnloadedUnits = (value?: number) => {
    this.unloadedUnits = value;
  };

  @action public setUnloadedWeight = (value?: number) => {
    this.unloadedWeight = value;
  };

  @action public setReasonId = (value: number | undefined) => {
    this.reasonId = value;
  };

  @computed get isUnloaded(): boolean {
    return !!this.unloadedUnits && !!this.unloadedWeight && this.storage.isValid;
  }

  @computed get bagIcon(): { name: string; color: string } {
    if (this.unloadedUnits === this.expectedUnits) {
      return { name: 'check', color: COLORS.GREEN };
    } else if (this.unloadedUnits && this.unloadedUnits !== this.expectedUnits) {
      return { name: 'failed', color: COLORS.RED };
    }

    return { name: '', color: '' };
  }

  @computed get weightIcon(): { name: string; color: string } {
    if (this.unloadedWeight === this.expectedWeight) {
      return { name: 'check', color: COLORS.GREEN };
    } else if (this.unloadedWeight && this.unloadedWeight !== this.expectedWeight) {
      return { name: 'failed', color: COLORS.RED };
    }

    return { name: '', color: '' };
  }
}

export default UnloadableAvailableExcessOrOrphanCoffee;
