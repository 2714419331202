import React, { useState } from 'react';
import { observer } from 'mobx-react';

import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import BaseGroupComponent from 'components/groups/BaseGroup';
import SelectOptionArray, { SelectOption } from 'types/model/selectOption';
import { OpportunitySelectGroup } from './OpportunityFields';
import { IField } from 'components/shared/form/IField';
import useStores from 'utils/useStores';
interface ICustomerOrLeadProps {
  readOnly?: boolean;
  customers?: SelectOptionArray;
  customerLeads?: SelectOptionArray;
  selectedCustomerLeadId?: string;
  selectedCustomerId?: string;
  opportunitySourceIdField?: IField;
  errors?: string[];
  children?;
}

export interface ICustomerSelectionProps {
  setIsNewLead: (isNewLead: boolean) => void;
  children?: any;
  customers: SelectOptionArray;
  selectedCustomerId?: string;
  customerLeads: SelectOptionArray;
  selectedCustomerLeadId?: string;

  onCustomerIdSelect: (value?: string) => void;
  onCustomerLeadIdSelect: (value?: string) => void;
  readOnly: boolean;
  errors?: string[];
}

function CustomerSelectionComponent({
  readOnly,
  setIsNewLead,
  customerLeads,
  customers,
  selectedCustomerId,
  selectedCustomerLeadId,
  onCustomerIdSelect,
  onCustomerLeadIdSelect,
  errors,
}: ICustomerSelectionProps) {
  const CUSTOMER_PREFIX = 'CUS-';
  const CUSTOMER_LEAD_PREFIX = 'LEAD-';
  const { opportunityStore } = useStores();

  const mergedOptions: SelectOptionArray = customers
    .map(({ id, name }) => ({ id: CUSTOMER_PREFIX + id, name: name }))
    .concat(
      customerLeads.map(({ id, name }) => ({ id: CUSTOMER_LEAD_PREFIX + id, name: name }))
    )
    .sort((a: SelectOption, b: SelectOption) => {
      const nameA = a.name.trim().toLowerCase();
      const nameB = b.name.trim().toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  let selectedValue = '';
  if (selectedCustomerId) {
    selectedValue = CUSTOMER_PREFIX + selectedCustomerId;
  } else if (selectedCustomerLeadId) {
    selectedValue = CUSTOMER_LEAD_PREFIX + selectedCustomerLeadId;
  }

  const selectedCustomer = customers.find(
    (customer) => customer.id == selectedValue.slice(CUSTOMER_PREFIX.length)
  );

  // @ts-ignore: Wrongly typed Options
  const qualification = selectedCustomer ? selectedCustomer.qualification : '';

  const onSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedFullId = e.target.value;

    const selectedCustomer = customers.find(
      (customer) => customer.id == selectedFullId.slice(CUSTOMER_PREFIX.length)
    );

    opportunityStore.setSelectedCustomer(selectedCustomer);
    const selectedCustomerLead = customerLeads.find(
      (customerLead) =>
        customerLead.id == selectedFullId.slice(CUSTOMER_LEAD_PREFIX.length)
    );
    if (selectedFullId.startsWith(CUSTOMER_PREFIX) && selectedCustomer) {
      onCustomerIdSelect(selectedCustomer.id.toString());
      onCustomerLeadIdSelect(undefined);
    } else if (selectedFullId.startsWith(CUSTOMER_LEAD_PREFIX) && selectedCustomerLead) {
      onCustomerLeadIdSelect(selectedCustomerLead.id.toString());
      onCustomerIdSelect(undefined);
    }
  };

  return (
    <>
      <input
        type='hidden'
        value={selectedCustomerId || ''}
        name='opportunity[customer_id]'
      />
      <input
        type='hidden'
        value={selectedCustomerLeadId || ''}
        name='opportunity[customer_lead_id]'
      />
      <SelectGroup
        label={I18n.translate('opportunities.form.customer_lead') + '*'}
        placeholder={I18n.translate('opportunities.form.select_customer_lead')}
        options={mergedOptions}
        value={selectedValue}
        onChange={onSelect}
        disabled={readOnly}
        errors={errors}
        data-cy='select_customer'
      />
      <BaseGroupComponent emptyLabel={true}>
        <button
          className={'button button--block button--green'}
          onClick={() => setIsNewLead(true)}
          disabled={readOnly}
          data-cy='create_customer_lead_button'
        >
          {I18n.translate(`opportunities.form.new_customer_lead`)}
        </button>
      </BaseGroupComponent>
      <InputGroupComponent
        label={I18n.translate('opportunities.form.customer_qualification')}
        value={qualification}
        readOnly={true}
      />
    </>
  );
}

export interface INewCustomerLeadProps {
  setIsNewLead: (boolean) => void;
  children?: any;
}

function NewCustomerLeadComponent({ setIsNewLead }: INewCustomerLeadProps) {
  const [emailAddress, setEmailAddress] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [secondSurname, setSecondSurname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');

  return (
    <>
      <InputGroupComponent
        onChange={(e) => setEmailAddress(e.target.value)}
        value={emailAddress}
        label={I18n.translate('opportunities.form.email_address')}
        type='email'
        data-cy='email'
        name={'customer_lead[email_address]'}
        placeholder=' '
        required={true}
      />
      <BaseGroupComponent emptyLabel={true}>
        <button
          className={'button button--block button--blue'}
          onClick={() => setIsNewLead(false)}
          data-cy='use_regular_customer'
        >
          {I18n.translate('opportunities.form.regular_customer')}
        </button>
      </BaseGroupComponent>
      <InputGroupComponent
        onChange={(e) => setFirstName(e.target.value)}
        value={firstName}
        label={I18n.translate('attributes.first_name')}
        data-cy='first_name'
        name={'customer_lead[first_name]'}
        required={true}
        placeholder=' '
      />
      <InputGroupComponent
        onChange={(e) => setSurname(e.target.value)}
        value={surname}
        label={I18n.translate('attributes.surname')}
        data-cy='surname'
        name={'customer_lead[surname]'}
        required={true}
        placeholder=' '
      />
      <InputGroupComponent
        onChange={(e) => setMiddleName(e.target.value)}
        value={middleName}
        label={I18n.translate('attributes.middle_name')}
        data-cy='middle_name'
        name={'customer_lead[middle_name]'}
      />
      <InputGroupComponent
        onChange={(e) => setSecondSurname(e.target.value)}
        value={secondSurname}
        label={I18n.translate('attributes.second_surname')}
        data-cy='second_surname'
        name={'customer_lead[second_surname]'}
      />
      <InputGroupComponent
        onChange={(e) => setPhoneNumber(e.target.value)}
        value={phoneNumber}
        label={I18n.translate('attributes.phone_number')}
        data-cy='phone_number'
        name={'customer_lead[phone_number]'}
      />
      <InputGroupComponent
        onChange={(e) => setCompanyName(e.target.value)}
        value={companyName}
        label={I18n.translate('attributes.company_name')}
        data-cy='company_name'
        name={'customer_lead[company_name]'}
        required={true}
      />
    </>
  );
}
function CustomerOrLeadComponent({
  readOnly,
  customers,
  selectedCustomerId,
  customerLeads,
  selectedCustomerLeadId,
  opportunitySourceIdField,
  errors,
}: ICustomerOrLeadProps) {
  const [isNewLead, setIsNewLead] = useState(false);
  const [customerLeadId, setCustomerLeadId] = useState<string | undefined>(
    selectedCustomerLeadId
  );
  const [customerId, setCustomerId] = useState<string | undefined>(selectedCustomerId);

  const onSetLead = (id) => setCustomerLeadId(id);

  const customerBlock = isNewLead ? (
    <NewCustomerLeadComponent setIsNewLead={setIsNewLead} />
  ) : (
    <CustomerSelectionComponent
      readOnly={readOnly || false}
      customers={customers || []}
      customerLeads={customerLeads || []}
      selectedCustomerId={customerId}
      selectedCustomerLeadId={customerLeadId}
      onCustomerIdSelect={setCustomerId}
      onCustomerLeadIdSelect={onSetLead}
      setIsNewLead={setIsNewLead}
      errors={errors}
    />
  );

  return (
    <>
      {customerBlock}
      <OpportunitySelectGroup
        field={opportunitySourceIdField}
        value={opportunitySourceIdField?.value}
      />
    </>
  );
}

export default observer(CustomerOrLeadComponent);
