import { observer } from 'mobx-react';
import React from 'react';

import { useFilterContext } from 'utils/filterContext';
import useStores from 'utils/useStores';

import InventoryTable from './InventoryTable';
import SelectedInventory from './SelectedInventory';

function TransportLegIndex() {
  const { transportStore } = useStores();
  const { filters } = useFilterContext();

  const filteredInventory = React.useMemo(
    () =>
      transportStore.transportables.filter((item) =>
        Object.entries(filters).every(([_, { filter, value }]) => {
          if (value) {
            if (Array.isArray(value)) {
              return value.some((filterValue) =>
                [
                  item.attributes.localIdentifier,
                  item.attributes.producer,
                  item.attributes.certification,
                ].some((attribute) => filter(attribute, filterValue))
              );
            } else {
              return (
                filter(item.attributes.localIdentifier, value) ||
                filter(item.attributes.producer, value) ||
                filter(item.attributes.certification, value)
              );
            }
          } else {
            return true;
          }
        })
      ),
    [filters]
  );

  return (
    <>
      <SelectedInventory />

      <InventoryTable inventoryItems={filteredInventory} />
    </>
  );
}

export default observer(TransportLegIndex);
