import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';

import Overlay from 'shared/Overlay';

import InputGroupComponent from '../../components/groups/InputGroup';

import Grid from 'components/elements/Grid';

import { VARIANT } from 'constants/inputTypes';

import useStores from 'utils/useStores';
import UnloadItem from '../model/UnloadItem';
import styled from 'styled-components';
import UnloadableIco from 'transports/model/UnloadableIco';
import UnloadableItem from 'transports/model/UnloadableItem';
import Storage from './Storage';
import SelectOptionArray from 'types/model/selectOption';

const UnloadTransportableOverlayWrapper = styled.div`
  width: 37.5em;
`;

interface UnloadLegProps {
  transportable: UnloadableItem | UnloadableIco;
}

const UnloadTransportableOverlay = ({ transportable }: UnloadLegProps) => {
  const { storage } = transportable;
  const { unloadStore } = useStores();

  const [showOverlay, setOverlay] = useState(false);
  const [validBags, setValidBags] = useState(false);
  const [validWeight, setValidWeight] = useState(false);

  useEffect(() => {
    setValidBags(bagValidator());
  }, [transportable.unloadedUnits]);

  useEffect(() => {
    setValidWeight(weightValidator());
  }, [transportable.unloadedWeight]);

  const handleNumericalInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setter: (value: number | undefined) => void
  ) => {
    let newValue: number | undefined;

    if (e.target.value) {
      switch (e.target.inputMode) {
        case 'numeric':
          newValue = parseInt(e.target.value, 10);
          break;
        case 'decimal':
          newValue = parseFloat(e.target.value);
          break;
        default:
          break;
      }
    }

    setter(newValue);
  };

  const handlePrimaryClick = () => {
    setOverlay(false);
  };

  const handleSecondaryClick = () => {
    transportable.setReasonId(undefined);
    transportable.setUnloadedUnits(0);
    transportable.setUnloadedWeight(0);

    if (storage) {
      const { setArea, setBin, setLevel, setPosition } = storage;

      setArea('');
      setBin('');
      setLevel('');
      setPosition('');
    }

    setOverlay(false);
  };

  const bagValidator = () => {
    return transportable.unloadedUnits === transportable.expectedUnits;
  };

  const weightValidator = () => {
    return transportable.unloadedWeight === transportable.expectedWeight;
  };

  let invalidOptions: SelectOptionArray | undefined = undefined;

  if (
    (transportable.unloadedUnits && !validBags) ||
    (transportable.unloadedWeight && !validWeight)
  ) {
    invalidOptions = unloadStore.receptionDifferenceReasons;

    // Remove dispatch error condition from invalid options of UnloadItem(Lot and LotSet)
    if (!(transportable instanceof UnloadItem)) {
      invalidOptions = invalidOptions.filter((option) => option.id !== 3);
    }
  }

  let saveEnabled = !!(
    (validBags && validWeight) ||
    (transportable && transportable.reasonId && transportable.reasonId > 0)
  );

  if (storage) {
    saveEnabled = saveEnabled && storage.isValid;
  }

  return (
    <>
      {showOverlay && (
        <Overlay
          id={transportable.identifier}
          title={I18n.translate('headlines.unload_lot', {
            lot_id: transportable.identifier,
          })}
          primaryDisabled={!saveEnabled}
          primaryText={I18n.translate('buttons.save')}
          onPrimaryClick={() => {
            handlePrimaryClick();
          }}
          secondaryText={I18n.translate('buttons.close')}
          onSecondaryClick={() => {
            handleSecondaryClick();
          }}
          options={invalidOptions}
          optionLabel={I18n.translate('unload_lot.reason_for_weight_difference')}
          optionPlaceholder={I18n.translate('unload_lot.select_a_reason')}
          selectedValue={transportable.reasonId}
          handleReasonChange={(e) =>
            transportable.setReasonId(parseInt(e.target.value, 10))
          }
        >
          <UnloadTransportableOverlayWrapper>
            <Grid grid={8}>
              <InputGroupComponent
                label={I18n.translate('unload_lot.bags')}
                variant={VARIANT.INTEGER}
                value={transportable.unloadedUnits ?? ''}
                validator={bagValidator}
                data-cy='unloaded_bags'
                onChange={(e) =>
                  handleNumericalInputChange(e, transportable.setUnloadedUnits)
                }
              />
              <InputGroupComponent
                label={I18n.translate('unload_lot.expected')}
                defaultValue={transportable.expectedUnits}
                readOnly={true}
              />
              <InputGroupComponent
                label={I18n.translate('unload_lot.weight')}
                variant={VARIANT.DECIMAL}
                append='kg'
                value={transportable.unloadedWeight ?? ''}
                validator={weightValidator}
                data-cy='unloaded_weight'
                onChange={(e) =>
                  handleNumericalInputChange(e, transportable.setUnloadedWeight)
                }
              />
              <InputGroupComponent
                label={I18n.translate('unload_lot.expected')}
                defaultValue={transportable.expectedWeight}
                readOnly={true}
                append='kg'
              />
            </Grid>

            {storage && <Storage storage={storage} />}
          </UnloadTransportableOverlayWrapper>
        </Overlay>
      )}

      <button
        onClick={() => setOverlay(!showOverlay)}
        className='button button--block button--small'
      >
        {transportable.isUnloaded
          ? I18n.translate('unload_lot.edit')
          : I18n.translate('unload_lot.unload')}
      </button>
    </>
  );
};

export default observer(UnloadTransportableOverlay);
