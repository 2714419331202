import { deserialise } from 'kitsu-core';
import { action, computed, observable } from 'mobx';

import type { Transport } from 'types/model/transport';

import type ExportsTransport from 'exports/types/transport';

import { customFetch } from 'utils/fetch';

import UnloadLeg from '../model/UnloadLeg';
import UnloadableItem from '../model/UnloadableItem';
import UnloadItem from '../model/UnloadItem';
import UnloadableLostCoffee from 'transports/model/UnloadableLostCoffee';
import UnloadableIco from 'transports/model/UnloadableIco';
import UnloadableAvailableExcessOrOrphanCoffee from 'transports/model/UnloadableAvailableExcessOrOrphanCoffee';
import SelectOptionArray from 'types/model/selectOption';

class UnloadStore {
  public receptionDifferenceReasons: SelectOptionArray;
  public transport: Transport | ExportsTransport;

  @observable public truckGrossWeight: number | string;
  @observable public receivedById = -1;
  @observable public truckNetWeight: number | string;
  @observable public unloadedLegs = Array<number>();
  @observable public baseDataSaved = false;

  public unloadLegs = observable.array<UnloadLeg>();

  constructor(
    transport: Transport | ExportsTransport,
    receptionDifferenceReasons: SelectOptionArray,
    private unloadTransportPath: string,
    private redirectPath: string
  ) {
    this.transport = deserialise(transport).data as Transport | ExportsTransport;
    this.truckGrossWeight = '';
    this.truckNetWeight = '';
    this.receptionDifferenceReasons = receptionDifferenceReasons;

    if (this.transport.transport_legs) {
      const unloadLegs = this.transport.transport_legs.map((leg) => new UnloadLeg(leg));

      this.unloadLegs = observable.array(unloadLegs);
    } else {
      const unloadLeg = new UnloadLeg(this.transport.transport_leg, {
        hasStorage: this.transport.is_storable,
      });

      this.unloadLegs = observable.array([unloadLeg]);
    }
  }

  @action public setTruckGrossWeight = (value: number) => {
    this.truckGrossWeight = value;
  };

  @action public setReceivedById = (value: number) => {
    this.receivedById = value;
  };

  @action public setTruckNetWeight = (value: number) => {
    this.truckNetWeight = value;
  };

  @action public setBaseDataSaved = (value: boolean) => {
    this.baseDataSaved = value;
  };

  @action public addCompletedLeg = (legId: number) => {
    this.unloadedLegs.push(legId);
  };

  @action public reasonValue = (id: number) => {
    const reason = this.receptionDifferenceReasons.find((r) => r.id === id);
    return reason?.name;
  };

  @action public submit = () =>
    customFetch(this.unloadTransportPath, this.submitData()).then(
      () => (window.location.href = this.redirectPath)
    );

  private submitData = () => {
    const data = {
      legs: {},
      lots: {},
      lot_sets: {},
      lost_coffees: {},
      icos: {},
      milling_outputs: {},
      transport: {
        dest_gross_weight: { value: this.truckGrossWeight, unit: 'unit_kg' },
        dest_net_weight: { value: this.truckNetWeight, unit: 'unit_kg' },
        received_by_id: this.receivedById,
      },
    };

    this.unloadLegs.forEach((leg) => {
      leg.unloadItems.forEach((item: UnloadableItem | UnloadableIco) => {
        const { storage } = item;

        let storageAttributes = {};

        if (storage) {
          storageAttributes = storage;
        }

        const serializedItem = {
          ...storageAttributes,
          bags_received_at_mill: item.unloadedUnits,
          received_at_mill_weight: { value: item.unloadedWeight, unit: 'unit_kg' },
          received_in_mill_at: new Date(),
          reception_difference_reason_id: item.reasonId,
        };

        if (item instanceof UnloadItem) {
          if (item.type === 'lot') {
            data.lots[item.lot.id] = serializedItem;
          } else if (item.type === 'lot_set') {
            data.lot_sets[item.lot.id] = serializedItem;
          }
        } else if (item instanceof UnloadableLostCoffee) {
          data.lost_coffees[item.id] = {
            id: item.id,
          };
        } else if (item instanceof UnloadableAvailableExcessOrOrphanCoffee) {
          data.milling_outputs[item.id] = serializedItem;
        } else if (item instanceof UnloadableIco) {
          data.icos[item.id] = {
            ...storageAttributes,
            id: item.id,
            received_units: item.unloadedUnits,
            received_weight: { value: item.unloadedWeight, unit: 'unit_kg' },
            reception_difference_reason_id: item.reasonId,
          };
        } else {
          console.log('Unknown type');
        }
      });
    });

    return data;
  };

  @computed get baseDataIsFilled(): boolean {
    if (this.truckGrossWeight) {
      return this.truckGrossWeight > 0 && this.receivedById > 0;
    }
    return false;
  }

  @computed get unloaded(): boolean {
    const unloadedLegs = this.unloadLegs.filter((leg) => leg.unloaded);
    return unloadedLegs.length === this.unloadLegs.length;
  }
}

export default UnloadStore;
