import React, { useState } from 'react';
import Inventory from 'inventoryReconciliations/type/inventory';
import Checkbox from 'shared/Checkbox';
import InventoryChangeOverlay from './InventoryChangeOverlay';
import Icon from 'components/elements/Icon';
import COLORS from 'constants/colors';
import { customFetch } from 'utils/fetch';
import Routes from 'routes';
import useStores from 'utils/useStores';
import { observer } from 'mobx-react';
interface InventoryRowProps {
  inventory: Inventory;
}

const InventoryRow = ({ inventory }: InventoryRowProps): JSX.Element => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [observations, setObservations] = useState('');
  const [checked, setChecked] = useState(false);
  const {
    mainIdentifier,
    office,
    location,
    quality,
    weight,
    differences,
    canConcile,
    authorized,
  } = inventory;
  const { inventoryReconciliationStore } = useStores();
  const differenceReasonText = () => {
    const text: string[] = [];
    if (differences?.location) {
      text.push(differences.location.name);
    }
    if (differences?.weight) {
      text.push(differences.weight.value.toString());
    }
    return (
      <>
        {text.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            {index < text.length - 1 && <br />}
          </React.Fragment>
        ))}
      </>
    );
  };

  const handleApproveRejectClick = async (action: string) => {
    try {
      await customFetch(
        Routes.api_v1_inventory_reconciliations_reconcile_inventories_path(),
        {
          data: inventoryReconciliationStore.serialize([inventory], action, observations),
        },
        'POST'
      );

      window.location.href = window.location.pathname;
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancelClick = () => {
    setShowOverlay(false);
  };

  const onSelectInventory = (checked: boolean) => {
    setChecked(checked);
    if (checked) {
      inventoryReconciliationStore.addInventory(inventory);
    } else {
      inventoryReconciliationStore.removeInventory(inventory);
    }
  };

  const disabledCheckbox = differences?.location?.error || !canConcile || !authorized;
  return (
    <tr>
      <td className='table-border'>
        <Checkbox
          checked={checked}
          disabled={disabledCheckbox}
          onChange={onSelectInventory}
        />
      </td>
      <td className='table-border'>{mainIdentifier}</td>
      <td className='table-border'>{office}</td>
      <td className='table-border'>{location}</td>
      <td className='table-border'>{quality}</td>
      <td className='table-border'>{weight}</td>
      <td className='table-border'>{differenceReasonText()}</td>
      <td className='table-border'>
        <Icon
          icon={'info'}
          onClick={() => setShowOverlay(!showOverlay)}
          color={COLORS.BLUE}
        />
        {showOverlay && (
          <InventoryChangeOverlay
            onApproveClick={() => handleApproveRejectClick('approve')}
            onRejectClick={() => handleApproveRejectClick('reject')}
            onCancelClick={handleCancelClick}
            inventory={inventory}
            observations={observations}
            setObservations={setObservations}
          />
        )}
      </td>
    </tr>
  );
};

export default observer(InventoryRow);
