import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import { useFilterContext } from 'utils/filterContext';
import useStores from 'utils/useStores';

import Tile, { TileDisclosure, TileHeader } from 'shared/Tile';

import InventoryItem from './InventoryItem';

import Routes from 'routes';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import SelectGroup from 'components/groups/SelectGroup';

const Actions = styled.div`
  display: grid;
  grid-template-columns: 15em 12em 25em;
  grid-column-gap: 1.25em;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

interface SelectedInventoryTableProps {
  collapse: boolean;
}

function SelectedInventoryTable({ collapse }: SelectedInventoryTableProps) {
  const { transportStore } = useStores();

  return (
    <table
      className={classNames('table', 'table--inverted-stripes', 'sets-table', {
        'sets-table--collapsed': collapse,
      })}
    >
      <tbody>
        {transportStore.selectedInventory.map((inventory) => (
          <InventoryItem
            key={inventory.attributes.localIdentifier}
            inventory={inventory.attributes}
            checked={inventory.selected}
            onCheck={() => transportStore.deselectInventory(inventory)}
            showDragHandle={transportStore.sortable}
          />
        ))}
      </tbody>
    </table>
  );
}

function SelectedInventory() {
  const sensors = useSensors(useSensor(PointerSensor));

  const { transportStore } = useStores();
  const { updateFilter } = useFilterContext();

  const [collapsed, setCollapsed] = React.useState(true);
  const [selectedValue, setSelectedValue] = useState('0');

  React.useEffect(() => {
    if (!transportStore.selectedInventory.length) {
      setCollapsed(true);
    }
  }, [transportStore.selectedInventory.length]);

  const handleDisclosureClick = React.useCallback(
    () => setCollapsed(!collapsed),
    [collapsed]
  );

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => updateFilter(event.target.name, event.target.value);

  const selectHandleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const value = event.target.value;

    const filterMapping = {
      '0': ' ',
      '1': ['-LOT-', '-SET-'],
      '2': '-FRE-',
      '3': '-EXS-',
      '4': '-CAR-',
    };

    const filterValue = filterMapping[value];
    if (filterValue) {
      updateFilter(event.target.name, filterValue);
      setSelectedValue(event.target.value);
    }
  };

  const handleOnDragEnd = ({ active, over }: DragEndEvent) => {
    if (typeof active.id === 'string' && over && typeof over.id === 'string') {
      transportStore.orderInventory(active.id, over.id);
    }
  };

  return (
    <>
      <Tile collapsibleIndicator={!!transportStore.selectedInventory.length && collapsed}>
        <>
          <TileHeader className='selected-lots__header'>
            <TileDisclosure
              disabled={!transportStore.selectedInventory.length}
              handleCollapsible={handleDisclosureClick}
              collapsed={collapsed}
            />
            <Actions>
              <input
                type='text'
                placeholder={I18n.translate('transports.show.filter')}
                onChange={handleOnChange}
                name='local_identifier'
              />

              {transportStore.enableLotTypeFilter ? (
                <SelectGroup
                  name='local_identifier'
                  value={selectedValue}
                  options={[
                    {
                      id: '0',
                      name: I18n.translate('transports.show.select.default'),
                      value: '0',
                    },
                    {
                      id: '1',
                      name: I18n.translate('transports.show.select.vanilla'),
                      value: '1',
                    },
                    {
                      id: '2',
                      name: I18n.translate('transports.show.select.fractions_rejections'),
                      value: '2',
                    },
                    {
                      id: '3',
                      name: I18n.translate('transports.show.select.excelso'),
                      value: '3',
                    },
                    {
                      id: '4',
                      name: I18n.translate('transports.show.select.caracol'),
                      value: '4',
                    },
                  ]}
                  onChange={selectHandleOnChange}
                />
              ) : (
                <div></div>
              )}

              <div className='selected-lots__actions'>
                <span className='bold'>
                  {I18n.translate('transports.show.bags', {
                    count: transportStore.selectedTotalBags,
                  })}
                </span>
                <span className='bold'>{`${transportStore.totalWeight} kg`}</span>
                <div className='l-item-stack-horizontal'>
                  <a
                    href={Routes.in_progress_transports_path()}
                    className='button button--second'
                  >
                    {I18n.translate('buttons.cancel')}
                  </a>
                  <button
                    disabled={!transportStore.isFormValid}
                    className='button'
                    data-cy='continue_button'
                  >
                    {I18n.translate('buttons.continue')}
                  </button>
                </div>
              </div>
            </Actions>
          </TileHeader>

          {!collapsed &&
            !!transportStore.selectedInventory.length &&
            (transportStore.sortable ? (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleOnDragEnd}
              >
                <SortableContext
                  items={transportStore.selectedInventory.map(
                    (inv) => inv.attributes.localIdentifier
                  )}
                  strategy={verticalListSortingStrategy}
                >
                  <SelectedInventoryTable collapse={collapsed} />
                </SortableContext>
              </DndContext>
            ) : (
              <SelectedInventoryTable collapse={collapsed} />
            ))}
        </>
      </Tile>

      {transportStore.selectedInventory.map((inventory, index) => (
        <Fragment key={inventory.attributes.localIdentifier}>
          <input
            type='hidden'
            name={`transport[${inventory.type}s][][id]`}
            value={inventory.attributes.id}
          />

          {transportStore.sortable && (
            <input
              type='hidden'
              name={`transport[${inventory.type}s][][order]`}
              value={index}
            />
          )}
        </Fragment>
      ))}
    </>
  );
}

export default observer(SelectedInventory);
